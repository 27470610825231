@import "/home/ec2-user/workspace/_Werken_mow-subsidies-app_master/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/_Werken_mow-subsidies-app_master/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
// This file is imported after all core/plugin stylesheets.
// This allows you to overwrite any styling you want.
// Keep in mind variables and mixins defined here will have no effect on earlier imported styling.
// If you want that, you should define them in includes.scss.
// Anything defined in the includes files (from core and theme) is available for use.

.editor-component .input-component.vl-col--1-2 {
  max-width: 80%;
}
